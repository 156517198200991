<template>
  <a-modal :class="$style.container" v-model="isShow" :title="(this.form.auditStatus === 'pass' ? '审核' : '驳回')" @cancel="close"
           @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :class="$style.form__ct" :model="form" :rules="formRules" class="al-form"
                  :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
      <a-form-model-item label="" prop="id" v-show="false">
        <a-input v-model="form.afterSaleId"/>
      </a-form-model-item>
      <a-form-model-item label="" prop="auditStatus" v-show="false">
        <a-input v-model="form.auditStatus" />
      </a-form-model-item>
       <a-form-model-item label="商品退款金额" prop="refundAmount" v-if="this.form.auditStatus === 'pass'">
        <a-input-number v-model="form.refundAmount" :max="paid" style="width: 300px;color:red;"/>
      </a-form-model-item>
       <a-form-model-item label="运费退款：" v-if="this.form.auditStatus === 'pass'">
            <div v-if="form.ishipping == 0" style="color:#909399;">此订单运费已退</div>
            <div v-if="form.ishipping == 1">
                <span style="color:#E00000">￥{{form.shippingFee}}</span>
                <span style="color:#909399">&emsp;未发货整单退款必须退运费</span>
            </div>
            <div v-if="form.ishipping == 2">
                <div>
                    <span style="color:#E00000">￥{{form.shippingFee}}</span>
                    <span style="color:#909399">&emsp;根据实际情况选择退运费</span>
                </div>
                <a-radio-group  v-model="form.shippingRefund">
                    <a-radio :value="1">
                        退运费
                    </a-radio>
                    <a-radio :value="0">
                        不退运费
                    </a-radio>
                </a-radio-group>
            </div>
      </a-form-model-item>
       <a-form-model-item label="总退款金额" prop="refundAmount" v-if="this.form.auditStatus === 'pass'">
         <span style="color:#E00000">￥{{
            numberPorater.add(
                !isNaN(form.refundAmount)?form.refundAmount:0,
                (form.shippingRefund == 0 ? 0 : !isNaN(form.shippingFee)?form.shippingFee:0)
            )
         }}</span>
      </a-form-model-item>
      <a-form-model-item label="审核备注">
        <a-textarea v-model="form.auditMemo" placeholder="请输入" style="width: 300px;"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import floatObj from '@/utils/floatObj.js'
export default {
  inject: ['appRefs'],
  data () {
    const validRefundAmount = (rule, value, callback) => {
      if (this.form.auditStatus === 'pass' && !value) {
        return callback(new Error('请输入退款金额'))
      }
      if (!Number(value)) {
        return callback(new Error('请输入数值'))
      } else {
        if (value < 0 || value > this.paid) {
          return callback(new Error('请输入0~' + this.paid + '之间的数字'))
        }
        var pattern = /^\d+.?\d{0,4}$/
        if (!pattern.test(value)) {
          return callback(new Error('小数点后最多只能输入四位小数'))
        }
      }
      callback()
    }
    const jsOprater = floatObj.floatObj
    return {
      numberPorater: jsOprater,
      status: '',
      isShow: false, // 是否显示弹窗
      isDetermineLoading: false, // 是否显示确定loading
      form: {
        shippingRefund: 1, // 1表示退运费  2 表示不退运费
        afterSaleId: undefined,
        auditMemo: '',
        auditStatus: '',
        refundAmount: '',
        //
        refund: '',
        ishipping: undefined, // 售后单 列表 增加 ishipping 返回  0 不可退运费（代表该订单已退运费）  1 必退运费 （代表整单退）  2 可选退运费
        shippingFee: ''
      },
      paid: '',
      disabledStatus: false,
      loading1: false,
      formRules: {
        refund: [{ required: true, message: '请填写退款金额', trigger: 'blur' }],
        refundAmount: [
          { required: true, message: '请填写退款金额', trigger: 'blur' },
          { validator: validRefundAmount, trigger: 'change' }
        ],
        auditMemo: [{ required: true, message: '原因不能为空', trigger: 'change' }]
      }
    }
  },
  created () {

  },
  computed: {},
  methods: {
    init (item) {
    //   item = {
    //     id: '1536285143596032002',
    //     status: 0,
    //     statusName: '处理中',
    //     gmtCreate: '2022-06-13 17:51:35',
    //     gmtAudit: null,
    //     gmtCancel: null,
    //     gmtReceive: null,
    //     gmtComplete: null,
    //     idOOrder: '1534410434088538114',
    //     orderNo: '1534410434084343810',
    //     productName: 'Apple iPhone 13 Pro Max 128G 新款手机 移动联通电信5G全网通 官方授权全新国行正品 远峰蓝色1',
    //     spec: '1台',
    //     imgUrl: 'https://static.zaosl.com/2022/05/12/1524644389983453185.png',
    //     marketPrice: 9299,
    //     price: 1,
    //     paid: 1,
    //     refund: 1,
    //     qty: 1,
    //     memo: '哈哈',
    //     saleImages: null,
    //     auditMemo: '',
    //     saleMemos: [],
    //     shipping: null,
    //     address: null,
    //     type: 1,
    //     typeName: '退款',
    //     ishipping: 1,
    //     shippingFee: '123'
    //   }

      // isshipping 0 已退费 1未发货 要整单退 2:可选择退款
      const { ishipping, shippingFee } = item
      this.form.ishipping = ishipping
      this.form.shippingFee = shippingFee
      this.form.afterSaleId = item.id
      this.form.refundAmount = item.refund

      // eslint-disable-next-line eqeqeq
      if (ishipping == 0) {
        this.form.shippingRefund = 0
      }
      // eslint-disable-next-line eqeqeq
      if (ishipping == 1 || ishipping == 2) {
        this.form.shippingRefund = 1
      }

      this.paid = item.refund
    },
    init2 (item) {
      this.form.auditStatus = item
    },
    // 关闭弹窗
    close () {
      this.$refs.form.resetFields()
      this.form = {
        shippingRefund: 1, // 1表示退运费  2 表示不退运费
        afterSaleId: undefined,
        auditMemo: '',
        auditStatus: '',
        refundAmount: '',
        //
        refund: '',
        ishipping: undefined, // 售后单 列表 增加 ishipping 返回  0 不可退运费（代表该订单已退运费）  1 必退运费 （代表整单退）  2 可选退运费
        shippingFee: ''
      }
    },
    // 提交保存.
    async determine () {
    //   console.log('提交数据：', JSON.stringify(this.form))
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          delete params.refund
          delete params.ishipping
          delete params.shippingFee
          console.log(params, 'a-textareaa-textarea')
          let api = 'auditAfterSale'
          let query = {
            afterSaleId: this.form.afterSaleId
          }
          const res = await this.$store.dispatch('http', {
            api,
            params,
            query,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          //   console.log('res', res)
          if (res) {
            this.$store.dispatch('showToast', { message: (this.form.id ? '' : '') + '操作成功~' })
            this.isShow = false
            this.close()
            this.$emit('save')
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
