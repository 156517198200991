<template>
    <div>
        <a-form-model ref="form" :model="form" :rules="rules" layout="horizontal" :label-col="{ span: 6 }"
            :wrapper-col="{ span: 14 }">
            <a-form-model-item label="选择邮寄地址:" prop="addressId">
                <a-select v-model="form.addressId" placeholder="请填写收货地址"
                    @change="$refs.ruleForm.validateField(['addressId'])">
                    <a-select-option v-for="(item, index ) in addressList" :key="index" :value="item.id">
                       {{ item.provinceName }}{{ item.cityName }}{{ item.districtName }}{{ item.address }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false">
                <a-button @click="$emit('cancel')" style="margin-right:20px;">
                    取消
                </a-button>
                <a-button :loading="isDetermineLoading" type="primary" @click="onSubmit">
                    确定
                </a-button>
            </a-form-model-item>
        </a-form-model>
    </div>
</template>

<script>
export default {
  data () {
    return {
      isDetermineLoading: false,
      addressList: [], // 地址列表
      afterSaleId: undefined,
      form: {
        addressId: undefined
      },
      rules: {
        addressId: [{
          required: true,
          message: '请选择退货地址',
          trigger: 'blur'
        }]
      }
    }
  },
  async created () {

  },
  methods: {
    init (obj) {
      const { id } = obj
      this.afterSaleId = id
      this.$refs.form.clearValidate()
      const setDefaultAddressId = (defaultAddressId) => {
        this.form.addressId = defaultAddressId
      }
      this.getAddressList(setDefaultAddressId)
    },
    async getAddressList (setDefaultAddressId) {
      const res = await this.$store.dispatch('http', {
        api: 'getTenantAddress',
        params: { pageSize: 100 }
      })
      if (res) {
        this.addressList = res.records
        let hasDefaultAddressId = ''
        this.addressList.forEach(element => {
          if (element.defaultAddress === 1) {
            hasDefaultAddressId = element.id
          }
        })
        setDefaultAddressId(hasDefaultAddressId)
      }
    },
    onSubmit () {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          await this.$store.dispatch('http', {
            api: 'agreeReturnAfterSale',
            query: {
              afterSaleId: this.afterSaleId
            },
            params,
            complete: () => {
              setTimeout(() => {
                this.isDetermineLoading = false
              }, 500)
            },
            error: () => {
              this.$store.dispatch('showToast', { type: 'error', message: '出错了' })
            },
            success: () => {
              this.$store.dispatch('showToast', { message: '操作成功' })
              setTimeout(() => {
                this.$emit('success')
              }, 500)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
