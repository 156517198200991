<template>
  <div style="width: auto">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
      <!--      <a-button type="primary" @click="$refs.dialog.isShow = true">新建</a-button>-->
    </Breadcrumb>
    <!-- 查询 -->
    <a-form
      ref="formRef"
      layout="inline"
      :model="form"
    >
      <a-form-item>
        <a-input v-model="form.orderNo" placeholder="订单编号"/>
      </a-form-item>
      <a-form-item>
        <a-input v-model="form.productName" placeholder="货品名称"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="(e)=>{queryData()}">查询</a-button>
        <span style="margin-left:20px;"> </span>
        <a-button @click="resetForm">重置</a-button>
      </a-form-item>
    </a-form>
    <br/>
    <a-table :columns="columns"
      :data-source="list"
      :rowKey='record=>record.id'
      :pagination="false"
      :scroll="{ x: 2000, y: 580 }"
      bordered>
       <span slot="action1" slot-scope="scope">
        <div style="color: #4f9efa;"> {{ scope.id }}</div>
        <div style="color: #4f9efa;"> 类型:{{ scope.typeName }}</div>
        <div style="color: #4f9efa;"> 状态:{{ scope.statusName }}</div>
      </span>
      <span slot="action2" slot-scope="scope">
        <a  @click="zoom(scope.imgUrl)">
          <a-avatar shape="square"
            style="width:84px;height:84px;float: left"
            :src="scope.imgUrl" />
        </a>
        <div style="margin-top: 2px;margin-left: 90px;">
          {{ scope.productName }}({{ scope.spec }}*{{ scope.qty }})
        </div>
        <div style="margin-top: 2px;margin-left: 90px;">
          ￥{{ scope.price }}
        </div>
      </span>
      <span slot="action3" slot-scope="scope">
        <span v-if="scope.shipping != null">
          <div>快递公司：{{ scope.shipping.expressName }}</div>
          <div>快递单号：{{ scope.shipping.expressNo }}</div>
        </span>
      </span>
      <span slot="returnAddress" slot-scope="scope">
            <div v-if="scope.address">地址：{{ scope.address | formatAddress }}</div>
      </span>
      <span slot="action4" slot-scope="scope">
        <span style="width:80px;float: left;">{{ scope.memo }}</span>
        <span style="margin-left:82px;float: left;">
          <span v-for="(imgSale,index) in scope.saleImages" :key="index">
            <a  @click="zoom(imgSale)">
              <a-avatar shape="square"
                style="width:80px;height:80px;margin-left:5px;margin-top:2px;"
                :src="imgSale" />
            </a>
          </span>
        </span>
      </span>
      <span slot="action5" slot-scope="scope">
        <div v-for="(item,index) in scope.saleMemos" :key="index">
          {{ item.gmtModified }} &emsp;{{ item.memo }}
        </div>
      </span>
      <span slot="action6" slot-scope="scope">
        <div>申请时间：{{ scope.gmtCreate }}</div>
        <div v-if="scope.gmtCancel!=null">取消时间： {{ scope.gmtCancel }}</div>
        <div v-if="scope.gmtReceive!=null">收货时间： {{ scope.gmtReceive }}</div>
        <div v-if="scope.gmtAudit!=null">审核时间： {{ scope.gmtAudit }}</div>
        <div v-if="scope.gmtComplete!=null">完成时间： {{ scope.gmtComplete }}</div>
      </span>
      <span slot="action" slot-scope="scope">
        <div v-if="scope.type===1">
          <a  v-if="scope.status===0" @click="$refs.dialog2.init($utils.clone(scope));$refs.dialog2.isShow = true;$refs.dialog2.init2('pass')">审核</a>
          <a-divider type="vertical" v-if="scope.status===0"/>
          <a  v-if="scope.status===0" @click="$refs.dialog2.init($utils.clone(scope));$refs.dialog2.isShow = true;$refs.dialog2.init2('fail')">驳回</a>
          <a-divider type="vertical" v-if="scope.status===0"/>
          <a @click="$refs.dialog.init($utils.clone(scope));$refs.dialog.isShow = true">添加备注</a>
        </div>
        <div v-if="scope.type===2">
            <a href="#" v-if="scope.status===0" style="margin-right:10px;" @click="showModal(scope)"><a-spin size="small" v-if="scope.isLoading" />审核</a>
            <a-popconfirm
                title= "您确定操作?"
                ok-text="是"
                cancel-text="否"
                @confirm="statusConfirm(scope.id, 5)"
                @cancel="cancel"
                v-if="scope.status===4"
                >
                <a href="#"><a-spin size="small" v-if="scope.isLoading" />确认收货</a>
            </a-popconfirm>
            <a v-if="scope.status===5" @click="$refs.dialog2.init($utils.clone(scope));$refs.dialog2.isShow = true;$refs.dialog2.init2('pass')">审核</a>
            <a v-if="scope.status!=5 && scope.status!=6 && scope.status!=9"
                @click="$refs.dialog2.init($utils.clone(scope));$refs.dialog2.isShow = true;$refs.dialog2.init2('fail')">驳回</a>
            <a style="margin-left:10px" @click="$refs.dialog.init($utils.clone(scope));$refs.dialog.isShow = true">添加备注</a>
        </div>
      </span>
    </a-table>
    <br/>
    <a-modal
      :footer="null"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <img style="width:100%" :src="goodsImag"/>
    </a-modal>
    <!-- 分页 -->
    <a-pagination
      show-size-changer
      show-quick-jumper
      style="text-align: center"
      :total="total"
      :show-total="
        (total, range) => `共${total}条记录 第${range[0]}-${range[1]}条数据`
      "
      :page-size="pageSize"
      v-model="current"
      @change="onPageChange"
      @showSizeChange="onShowSizeChange"
    />
    <save-memo ref="dialog" @save="queryData"></save-memo>
    <save-audit ref="dialog2" @save="queryData"></save-audit>
    <a-modal
      v-model="selectAddressVisible"
      title="邮寄地址"
      :footer="null"
      :width="800"
    >
      <SelectBackAddress @cancel="selectAddressVisible = false" @success="selectAddressVisible = false; queryData()" ref="selectAddressRef"></SelectBackAddress>
    </a-modal>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import SaveMemo from './dialog/addMemo'
import SaveAudit from './dialog/audit'
import SelectBackAddress from './dialog/selectBackAddress.vue'

export default {
  components: {
    SaveAudit,
    Breadcrumb,
    SaveMemo,
    SelectBackAddress
  },
  data () {
    return {
      selectAddressVisible: false, // 选择邮寄地址窗口
      form: {
        orderNo: '',
        productName: ''
      },
      visible: false,
      goodsImag: '',
      tenantAddress: {},
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共${total}条记录,第${this.pagination.current}页`
      },
      total: 0,
      pageSize: 10,
      current: 1,
      breadList: [
        {
          name: '售后管理',
          url: ''
        }
      ],
      isQueryLoading: false,
      list: [],
      columns: [
        { title: '售后单号', dataIndex: '', key: '', width: 150, scopedSlots: { customRender: 'action1' } },
        { title: '订单信息', dataIndex: 'orderNo', key: 'orderNo', width: 120 },
        { title: '商品信息', dataIndex: '', key: '', width: 310, scopedSlots: { customRender: 'action2' } },
        { title: '物流信息', dataIndex: '', key: '', width: 220, scopedSlots: { customRender: 'action3' } },
        { title: '邮寄地址', dataIndex: '', key: '', width: 220, scopedSlots: { customRender: 'returnAddress' } },
        { title: '补充说明', dataIndex: '', key: '', width: 380, scopedSlots: { customRender: 'action4' } },
        { title: '备注', dataIndex: '', key: '', scopedSlots: { customRender: 'action5' }, width: 330 },
        { title: '时间', scopedSlots: { customRender: 'action6' }, key: '', width: 260 },
        { title: '操作', fixed: 'right', scopedSlots: { customRender: 'action' }, key: '', width: 230 }
      ]
    }
  },
  created () {
    this.queryData()
  },
  filters: {
    formatAddress (v) {
      return v.provinceName + ' ' + v.cityName + ' ' + v.districtName + ' ' + v.address
    }
  },
  methods: {
    showModal (item) {
      this.selectAddressVisible = true
      setTimeout(() => {
        this.$refs.selectAddressRef.init(item)
      }, 200)
    },
    // 重置表单
    resetForm () {
      this.form = {
        orderNo: '',
        productName: ''
      }
      this.queryData()
    },
    // 是否确认弹窗口  方法
    statusConfirm (id, status) {
      this.updateStatus(id, status)
    },
    // 售后单状态变更
    async updateStatus (id, status) {
      let api = 'agreeReturnAfterSale'
      if (status === 5) {
        api = 'confirmReceiptAfterSale'
      }
      const res = await this.$store.dispatch('http', {
        api: api,
        query: {
          afterSaleId: id
        }
      })
      if (res) {
        this.$store.dispatch('showToast', { message: '操作成功~' })
        this.queryData()
      }
    },
    cancel (values) {
      console.log(values)
    },
    // 改变页码
    onPageChange (pageNumber, pageSize) {
      this.queryData(event, pageNumber)
    },
    // 改变页码
    onShowSizeChange (current, pgSize) {
      console.log(pgSize)
      this.pageSize = pgSize
      this.queryData()
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log(val)
      if (this.pagination.current === val.current) {
        this.pagination.current = 1
      } else {
        this.pagination.current = val.current
      }
      this.pagination.pageSize = val.pageSize
      this.queryData()
    },
    async queryData (pageNumber) {
      // 如果不是点击的分页  而是点的查询按钮  需要修改  当前页current
      this.current = pageNumber ? this.current : 1
      console.log(this.current, this.pageSize)
      const res = await this.$store.dispatch('http', {
        api: 'afterSaleOrder',
        params: {
          ...this.form,
          pageNo: this.current,
          pageSize: this.pageSize
        }
      })
      this.list = res.records
      this.total = res.total
      this.$forceUpdate()
    },
    handleOk (e) {
      setTimeout(() => {
        this.visible = false
      })
      this.queryData()
    },
    handleCancel (e) {
      this.visible = false
    },
    zoom (scope) {
      this.visible = true
      this.goodsImag = scope
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .form__ct {
    padding: 10px 0;
  }

  .form__items {
    padding-top: 6px;
  }
}
</style>
